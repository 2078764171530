<template>
  <default-layout>
    <section>
      <div class="mb-1">
        <form v-form v-if="form">
          <input-date v-model="form.date" :error="$error('date')" />
          <input-time v-model="form.time" :error="$error('time')"/>
        </form>
      </div>
      <div class="text-center p-2">
        <button v-button:save @click="_save()" :disabled="processing"/>
        <button v-button:primary @click="_reset()" :disabled="processing">リセット</button>
      </div>
    </section>
  </default-layout>
</template>

<script>
import {Service} from './_resource';
import {announce} from "@/calico-vue/service";

export default {
  data() {
    return {
      form: null
    };
  },
  created() {
    this._getSaveForm();
  },
  methods: {
    _getSaveForm(){
      Service.saveForm().then(form => {
        this.form = form;
        console.log(this.form);
      }, () => {});
    },
    _save() {
      this.$processing(() =>
        Service.save(this.form).then(
          () => {
            announce.saved({title: '登録しました。'});
            this._getSaveForm();
          },
          error => {
            this.error = error;
          }
        )
      );
    },
    _reset() {
      this.$processing(() =>
        Service.reset().then(
          () => {
            announce.saved({title: 'リセットしました。'});
            this._getSaveForm();
          },
          error => {
            this.error = error;
          }
        )
      );
    },
  }
};
</script>
